@import "./utils/saas-variables";
//import image from "../"

.App {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: $headerHeight calc(100vh - #{$headerHeight});
  grid-template-areas:
    "header"
    "body"
    "footer";
  place-items: stretch;
  background-image: url("/bg.jpg");
  .AppHeader {
    grid-area: header;
  }
  .AppBody {
    grid-area: body;
  }
}

@media screen and (min-width: 900px) {
  .App {
    background-size: 100% 100%;
  }
}
