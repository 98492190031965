.selectedRecipes {
  margin-bottom: 20px;
  .recipeHolder {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .recipeSummary {
      margin-left: 7px;
    }
    button {
      margin-right: 7px;
      border-radius: 6px;
      padding-top: 0px;
      border-width: 1px;
      div {
        margin: 0px;
        padding: 0px;
      }
    }
  }
  .selectedRecipe {
    background-color: #e9ecef;
    border-radius: 6px;
  }
}
