.appHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.53;
  height: 100%;
  border-bottom: 1.2px solid #555;

  .appTitle {
    margin-top: 2px;
    color: black;
    font-style: italic;
    opacity: 1;
  }
}
