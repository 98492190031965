.recipe {
  display: flex;
  margin: 0px 30px;
  align-items: flex-start;

  .recipeLeftCol {
    margin: 0px 30px;
    width: 350px;
    background-color: white;
    border-radius: 5px;
    padding: 10px 20px;

    .recipeTextData {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-auto-rows: auto;

      label {
        margin-top: 3px;
      }
      input {
        margin-left: 10px !important;
        box-sizing: border-box;
        margin-bottom: 3px;
      }
    }
  }
  .recipeRightCol {
  }
}

@media all and (max-width: 992px) {
  .recipe {
    display: flex;
    flex-direction: column;

    .recipeRightCol {
      margin-top: 20px;
    }
  }
}
