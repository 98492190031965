.appBody {
  display: flex;
  margin: 20px 20px;
  height: 100%;
  overflow-x: scroll;
  .sidebarContainer {
    width: 300px;
    //max-width: 350px;
  }
}
